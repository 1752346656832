import styled from "@emotion/styled";
import { useFormikContext } from "formik";
import React, { LegacyRef, RefObject, useRef, useState } from "react";
import tw from "twin.macro";
import { Responsive } from "../utils/Responsive";
import { theme } from "../utils/theme";
import { useOnClickOutside } from "../utils/useOnClickOutside";

const Body = ({ ...props }) => {
    return (
        <Responsive
            sm={<MobileBody {...props} />}
            md={<DesktopBody {...props} />}
            lg={<WideBody {...props} />}
        />
    );
};

const MobileBody = styled.div`
    ${tw``};
    font-size: 10pt;
    font-family: "founders-text";
    text-transform: none !important;
    /* letter-spacing: -0.04em; */
    line-height: 1.2;
`;

const DesktopBody = styled(MobileBody)`
    font-size: 12pt;
`;

const WideBody = styled(DesktopBody)`
    font-size: 20pt;
`;

const Text = ({ ...props }) => {
    return (
        <Responsive
            sm={<MobileText {...props} />}
            md={<DesktopText {...props} />}
            lg={<WideText {...props} />}
        />
    );
};

const MobileText = styled.span`
    ${tw`uppercase`};
    font-size: 9pt;
    font-family: "founders-text";
    line-height: 1.4;
`;

const DesktopText = styled(MobileText)`
    font-size: 10pt;
`;

const WideText = styled(DesktopText)`
    font-size: 16pt;
`;

const H1 = ({ ...props }) => {
    return (
        <Responsive
            sm={<MobileH1 {...props} />}
            md={<DesktopH1 {...props} />}
            lg={<WideH1 {...props} />}
        />
    );
};

const MobileH1 = styled.h3`
    ${tw`uppercase`};
    font-size: 14pt;
    font-family: "founders-text";
    line-height: 1.4;
`;

const DesktopH1 = styled(MobileH1)`
    ${tw`text-3xl`};
`;

const WideH1 = styled(MobileH1)`
    font-size: 28pt;
`;

const FinePrint = styled.span`
    ${tw`uppercase`};
    font-size: 9pt;
    font-family: "founders-text";
    line-height: 1.4;
    text-transform: initial !important;
`;

const A = ({ ...props }) => {
    return (
        <Responsive
            sm={<MobileA {...props} />}
            md={<DesktopA {...props} />}
            lg={<DesktopA {...props} />}
        />
    );
};

const MobileA = ({ ...props }) => {
    return <MobileASpan as="a" {...props} />;
};

const MobileASpan = styled(Text)`
    ${tw`uppercase transition duration-150 ease`};
    font-family: "founders-text";
    &:hover {
        opacity: 1;
    }
`;

const DesktopA = styled(MobileA)`
    opacity: 0.5;
`;

const Navlink = ({ ...props }) => {
    return (
        <Responsive
            sm={<MobileNavlink {...props} />}
            md={<DesktopNavlink {...props} />}
            lg={<WideNavlink {...props} />}
        />
    );
};

const MobileNavlink = styled.button`
    ${tw`uppercase`};
    font-size: 17pt;
    letter-spacing: -0.05em;
    font-family: "founders-mono";
`;

const DesktopNavlink = styled(MobileNavlink)`
    ${tw`transition duration-150 ease hover:underline`};
    font-size: 20pt;
`;

const WideNavlink = styled(DesktopNavlink)`
    font-size: 30pt;
`;

const Input = ({ field, ...props }) => {
    return (
        <Responsive
            sm={<MobileInput {...field} {...props} />}
            md={<DesktopInput {...field} {...props} />}
            lg={<WideInput {...field} {...props} />}
        />
    );
};

const MobileInput = styled.input`
    ${tw`mb-1 bg-transparent outline-none w-full border-b border-black normal-case py-1 placeholder-current w-full rounded-none`};
    &::placeholder {
        color: #26262684;
    }
    border-color: #2626267d;
    font-size: 9pt;
    font-family: "founders-text";
`;

const DesktopInput = styled(MobileInput)`
    font-size: 10pt;
`;

const WideInput = styled(DesktopInput)`
    font-size: 18pt;
`;

const Select = ({ field, options, disableOption, ...props }) => {
    const [show, setShow] = useState<boolean>(false);
    const { setFieldValue } = useFormikContext();

    const { disabled, value, renderer, name } = props;

    const ref = useRef<HTMLDivElement | null>(null);
    useOnClickOutside(ref, () => setShow(false));

    return (
        <div
            style={{
                position: "relative",
                display: "inline-block",
                opacity: disabled ? 0.2 : 1,
            }}
            ref={ref}
        >
            <Responsive
                sm={
                    <>
                        <Navlink as="span" tw="pointer-events-none underline">
                            {(value !== name && renderer?.(value)) || value}
                        </Navlink>
                        <StyledSelect {...field} {...props}>
                            <option value={name} disabled hidden>
                                {props.name}
                            </option>
                            {options.map((option: string | number) => (
                                <option
                                    value={option}
                                    key={option}
                                    disabled={disableOption?.(option)}
                                >
                                    {renderer?.(option) || option}
                                </option>
                            ))}
                        </StyledSelect>
                    </>
                }
                md={
                    <>
                        <Navlink
                            as="span"
                            tw="underline cursor-pointer select-none"
                            onClick={() => setShow(!show)}
                            style={{
                                pointerEvents: disabled ? "none" : undefined,
                            }}
                        >
                            {(value !== props.name && renderer?.(value)) ||
                                value ||
                                name}
                        </Navlink>
                        {show && (
                            <ul
                                tw="absolute text-left px-0.5"
                                style={{
                                    top: "100%",
                                    width: "max-content",
                                    background: theme.colors.pistacio,
                                    maxHeight: 250,
                                    overflowY: "scroll",
                                }}
                            >
                                {options.map((option: string | number) => (
                                    <Option
                                        key={option}
                                        disabled={disableOption?.(option)}
                                        onClick={() => {
                                            setShow(false);
                                            setFieldValue(name, option);
                                        }}
                                    >
                                        {renderer?.(option) || option}
                                    </Option>
                                ))}
                            </ul>
                        )}
                    </>
                }
            />
        </div>
    );
};

const StyledSelect = styled.select`
    ${tw`w-full h-full absolute inset-0 opacity-0 cursor-pointer bg-transparent outline-none underline`};
    appearance: none;
`;

const Option = styled(Navlink)<{ disabled?: boolean }>`
    ${({ disabled }) => disabled && tw`opacity-25 pointer-events-none`};
`;

export { A, Body, H1, Text, FinePrint, Navlink, Input, Select };
